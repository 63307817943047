import { DrawerSection } from "components/utils/drawer";
import styles from "./AttachmentsSections.module.css";
import { CommonError } from "components/utils";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Order, OrderAttachment } from "api/orders/models";
import { orderActions } from "api/orders/actions";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiUploadFile } from "components/miloDesignSystem/atoms/icons/MdiUploadFile";
import cuid from "cuid";
import { ChangeEvent, useRef, useState } from "react";
import produce from "immer";
import { cx, getAnyErrorKey, mbToKb, queryString } from "utilities";
import { MdiBackup } from "components/miloDesignSystem/atoms/icons/MdiBackup";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { MdiDraft } from "components/miloDesignSystem/atoms/icons/MdiDraft";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import { MdiDownloadFile } from "components/miloDesignSystem/atoms/icons/MdiDownloadFile";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { FileExtension } from "components/miloDesignSystem/atoms/fileDownloadHandler/types";
import { ProgressBar } from "components/miloDesignSystem/atoms/progressBar";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";

interface Props {
  order: Order;
}

interface ProgressInfo {
  id: string;
  percentage: number;
  fileName: string;
  errorMessage: string;
  successMessage: string;
}

export const AttachmentsSection = ({ order }: Props) => {
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [progressInfos, setProgressInfos] = useState<ProgressInfo[]>([]);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const postAttachmentMutation = orderActions.usePostAttachment({ order });

  const uploadService = (file: File) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("order", order.uuid);
    const progresInfoFile: ProgressInfo = {
      id: cuid(),
      percentage: 0,
      fileName: file.name,
      errorMessage: "",
      successMessage: "",
    };

    setProgressInfos(prev => [...prev, progresInfoFile]);
    return postAttachmentMutation
      .mutateAsync(
        {
          data: formData,
          onUploadProgress: (event: ProgressEvent) => {
            setProgressInfos(prev =>
              produce(prev, draft => {
                const toUpdate = draft.find(el => el.id === progresInfoFile.id);
                assertIsDefined(toUpdate);
                toUpdate.percentage = Math.round((100 * event.loaded) / event.total);
              }),
            );
          },
        },
        {
          onError: error => {
            setProgressInfos(prev =>
              produce(prev, draft => {
                const toUpdate = draft.find(el => el.id === progresInfoFile.id);
                assertIsDefined(toUpdate);
                toUpdate.errorMessage = getAnyErrorKey(error);
              }),
            );
          },
        },
      )
      .then(payload => {
        setProgressInfos(prev =>
          produce(prev, draft => {
            const index = draft.findIndex(el => el.id === progresInfoFile.id);
            draft.splice(index, 1);
          }),
        );
      });
  };

  const uploadFiles = (files: File[]) => {
    files.forEach(file => {
      const fileSizeInKb = file.size / 1024;

      if (fileSizeInKb > FILE_SIZE_LIMIT) {
        const progresInfoFile: ProgressInfo = {
          id: cuid(),
          percentage: 0,
          fileName: file.name,
          errorMessage: `Rozmiar pliku nie może być większy niż ${fileSizeInKb / 1024}mb`,
          successMessage: "",
        };

        setProgressInfos(prev => [...prev, progresInfoFile]);

        return;
      }
      uploadService(file);
    });
  };

  const onDrop: React.DragEventHandler<HTMLInputElement> = event => {
    event.preventDefault();
    const filesAdded = event.dataTransfer
      ? event.dataTransfer.files
      : (event.target as HTMLInputElement).files || [];

    uploadFiles([...filesAdded]);
    setIsDraggingOver(false);
  };

  const onDragLeave: React.DragEventHandler<HTMLInputElement> = event => {
    event.preventDefault();
    event.stopPropagation();

    setIsDraggingOver(false);
  };

  const onDragOver: React.DragEventHandler<HTMLInputElement> = event => {
    event.preventDefault();
    event.stopPropagation();

    setIsDraggingOver(true);
  };

  function openFileChooser() {
    if (!inputRef.current) return;
    inputRef.current.value = "";
    inputRef.current.dispatchEvent(new MouseEvent("click"));
  }

  const inputAttributes = {
    type: "file",
    multiple: true,
    accept: "*",
    name: cuid(),
    style: { display: "none" },
    onChange: (event: ChangeEvent<HTMLInputElement>) =>
      uploadFiles([...((event.target as HTMLInputElement).files || [])]),
  };

  return (
    <DrawerSection title="Załączone dokumenty">
      <div
        ref={dropZoneRef}
        className={cx(
          styles.dropFileArea,
          { [styles.dropFileAreaDraggingOver]: isDraggingOver },
          "d-flex align-items-center justify-content-between p-3 mb-3",
        )}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <input {...inputAttributes} ref={inputRef} />
        <div className="d-flex align-items-center justify-content-between flex-1">
          <div className="d-flex align-items-center gap-3">
            <MdiBackup size="50" color="neutralBlack12" />
            <Typography fontSize="14" fontWeight="400">
              Przeciągnij i upuść aby dodać plik lub wybierz ze swojego urządzenia
            </Typography>
          </div>
          <Button variant="gray" size="small" startIcon={MdiUploadFile} onClick={openFileChooser}>
            Wybierz plik
          </Button>
        </div>
      </div>
      <div className="d-flex flex-column flex-1">
        {progressInfos.map(progress => (
          <AttachmentItem
            key={progress.id}
            progress={progress}
            attachment={{
              createdAt: new Date().toISOString(),
              file: progress.fileName,
              id: progress.id,
              order: order.uuid,
            }}
          />
        ))}
        <Attachments order={order} />
      </div>
    </DrawerSection>
  );
};

const Attachments = ({ order }: { order: Order }) => {
  const search = getAttachmentSearch(order);
  const { data, error, isLoading } = orderActions.useAttachments(search);

  if (isLoading) {
    return (
      <div className="ml-3 mt-1 mb-2">
        <Spinner size={28} />
      </div>
    );
  }

  if (error) {
    return <CommonError status={error._httpStatus_} />;
  }

  assertIsDefined(data);
  return (
    <div>
      {data.map(attachment => {
        return <AttachmentItem key={attachment.it} attachment={attachment} />;
      })}
    </div>
  );
};

const AttachmentItem = ({
  attachment,
  progress,
}: {
  attachment: OrderAttachment;
  progress?: ProgressInfo;
}) => {
  const deleteMutation = orderActions.useDeleteAttachment();
  if (progress) {
    return (
      <div className="w-60 mb-1 px-2 py-1">
        <div className="d-flex align-items-center gap-1">
          <MdiDraft size="14" color="neutralBlack48" />
          <Typography fontSize="12" fontWeight="600" noWrap>
            {getFileMeta(attachment.file).name} [{getFileMeta(attachment.file).format}]
          </Typography>
        </div>
        {!progress.errorMessage && (
          <div className="d-flex align-items-center gap-1 mr-2">
            <ProgressBar maxWidth={260} progress={progress.percentage} />
            <Typography fontSize="12" fontWeight="600">
              {progress.percentage}%
            </Typography>
          </div>
        )}

        {progress.errorMessage && (
          <Typography fontSize="12" fontWeight="600" color="danger500">
            {progress.errorMessage}
          </Typography>
        )}
      </div>
    );
  }
  return (
    <div className="w-60 d-flex align-items-center justify-content-between gap-1 mb-1 px-2 py-1">
      <div className="d-flex align-items-center gap-1">
        <MdiDraft size="14" color="neutralBlack48" />
        <Typography fontSize="12" fontWeight="600">
          {getFileMeta(attachment.file).name} [{getFileMeta(attachment.file).format}]
        </Typography>
      </div>
      <div className="d-flex align-items-center gap-1">
        <FileDownloadHandler
          factoryFn={() => ({
            url: attachment.file,
            name: getFileMeta(attachment.file).fullFileName,
            hasUrlWithDomain: true,
          })}
          type={getFileMeta(attachment.file).format as FileExtension}
        >
          {({ download, isLoading }) => (
            <Tooltip title="Pobierz plik">
              <IconButton
                icon={<MdiDownloadFile size="18" />}
                isLoading={isLoading}
                onClick={download}
                variant="transparent"
              />
            </Tooltip>
          )}
        </FileDownloadHandler>
        <IconButton
          variant="transparent"
          isLoading={deleteMutation.isLoading}
          icon={MdiClose}
          onClick={() => {
            deleteMutation.mutate(attachment.id);
          }}
        />
      </div>
    </div>
  );
};

const FILE_SIZE_LIMIT = mbToKb(30);

const getFileMeta = (fullPath: string) => {
  const fullFileName = fullPath.replace(/^.*[\\/]/, "") || "";
  const name = fullFileName
    .split(".")
    .slice(0, -1)
    .join("");

  return {
    format: fullFileName.split(".").pop(),
    name,
    fullFileName,
  };
};

export function getAttachmentSearch(order: Order) {
  return queryString.stringify({ order: order.uuid });
}
